import React, { useState } from 'react';
import './App.css';

import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';
import img7 from './images/7.jpg';
import img8 from './images/8.jpg';
import img9 from './images/9.jpg';
import img10 from './images/10.jpg';
import img11 from './images/11.jpg';
import img12 from './images/12.jpg';
import img13 from './images/13.jpg';
import img14 from './images/14.jpg';
import img15 from './images/15.jpg';
import img16 from './images/16.jpg';

import f1 from './images/f1.jpg';
import f2 from './images/f2.jpg';
import f3 from './images/f3.jpg';
import f4 from './images/f4.jpg';
import f5 from './images/f5.jpg';
import f6 from './images/f6.jpg';
import f7 from './images/f7.jpg';
import f8 from './images/f8.jpg';
import f9 from './images/f9.jpg';
import f10 from './images/f10.jpg';
import f11 from './images/f11.jpg';
import f12 from './images/f12.jpg';
import f13 from './images/f13.jpg';
import f14 from './images/f14.jpg';
import f15 from './images/f15.jpg';
import f16 from './images/f16.jpg';

import leiloada from './images/leiloada.jpg'; // Imagem a ser exibida após confirmar o leilão

function App() {
  const [images, setImages] = useState({
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    img9: img9,
    img10: img10,
    img11: img11,
    img12: img12,
    img13: img13,
    img14: img14,
    img15: img15,
    img16: img16    
  });

  const [modalImage, setModalImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);

  const modalImageMapping = {
    img1: f1,
    img2: f2,
    img3: f3,
    img4: f4,
    img5: f5,
    img6: f6,
    img7: f7,
    img8: f8,
    img9: f9,
    img10: f10,
    img11: f11,
    img12: f12,
    img13: f13,
    img14: f14,
    img15: f15,
    img16: f16,
  };

  const originalImageMapping = {
    img1: img1,
    img2: img2,
    img3: img3,
    img4: img4,
    img5: img5,
    img6: img6,
    img7: img7,
    img8: img8,
    img9: img9,
    img10: img10,
    img11: img11,
    img12: img12,
    img13: img13,
    img14: img14,
    img15: img15,
    img16: img16,
  };

  const handleImageClick = (key) => {
    if (images[key] !== leiloada) {
      setCurrentKey(key);
      setModalImage(modalImageMapping[key]);
      setModalOpen(true);
    }
  };

  const handleConfirm = () => {
    setImages((prevImages) => ({
      ...prevImages,
      [currentKey]: leiloada, 
    }));
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false); // Fecha o modal sem alterar a imagem
  };

  const handleRevert = (key) => {
    setImages((prevImages) => ({
      ...prevImages,
      [key]: originalImageMapping[key], // Reverte para a imagem original correta
    }));
  };

  return (
    <div className="app-container">
      <div className="image-grid">
        {Object.keys(images).map((key, index) => (
          <div className="image-wrapper" key={index}>
            <img
              src={images[key]}
              alt={`Model ${index + 1}`}
              onClick={() => handleImageClick(key)}
              className={images[key] === leiloada ? 'closed' : ''}
            />
            {images[key] === leiloada && (
              <button className="revert-button" onClick={() => handleRevert(key)}>Reverter</button>
            )}
          </div>
        ))}
      </div>

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <img src={modalImage} alt="Selected" className="modal-image" />
            <div className="modal-buttons">
              <button className="ok" onClick={handleConfirm}>Confirmar Leilão</button>
              <button className="cancel" onClick={handleCancel}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
